import { createIntlTranslations } from 'Features/Intl/fns';
import { GetStaticPropsContext } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';

export default function Page() {
  const { locale } = useRouter();

  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {locale === 'it' ? 'Pagina non trovata.' : 'Page not found.'}
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            {locale === 'it'
              ? 'La pagina che stai cercando non è più attiva.'
              : 'Sorry, the page you are looking for is no longer active.'}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link href="/" passHref>
              <a className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                {locale === 'it' ? 'Torna alla Home' : 'Go to Homepage'}
              </a>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}

export async function getStaticProps(context: GetStaticPropsContext) {
  const locale = context.locale?.startsWith('it') ? 'it' : 'en';
  const intl = await createIntlTranslations(locale);

  return {
    props: { ...intl },
  };
}
